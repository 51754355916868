import {
  Box,
  Button,
  Checkbox,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import tickCheckedCircle from "../../../../assets/icons/checked-circle.svg";
import EmailIcon from "../../../../assets/icons/email.svg";
import EyeSlashIcon from "../../../../assets/icons/eye-slash.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import PasswordIcon from "../../../../assets/icons/password.svg";
import tickCircle from "../../../../assets/icons/tick-circle.svg";
import { userSignUp } from "../../../../controller/services/auth";
import {
  setHospitalMenuSelected,
  setNurseMenuSelected,
} from "../../../../redux/slices/flags_slice";
import { setUserData } from "../../../../redux/slices/user_slice";
import Layout from "../../layout";

export default function Signup() {
  const [passwordVisibility, setPasswordVisibility] = useState("");
  const [currUserType, setCurrUserType] = useState("hospital");
  let userType = localStorage.getItem("userType") || currUserType;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.href.includes("hospital")) {
      localStorage.setItem("userType", "hospital");
      setCurrUserType("hospital");
    } else {
      localStorage.setItem("userType", "nurse");
      setCurrUserType("nurse");
    }
  }, [window.location.href]);

  const handleSignup = async (values) => {
    const fcmToken = localStorage.getItem("fcmToken");
    const payload = {
      userType: userType,
      email: values.email,
      password: values.password,
      fcmToken: fcmToken || "",
    };
    userSignUp(payload)
      .then((resp) => {
        localStorage.setItem("userData", JSON.stringify(resp.data));
        dispatch(setUserData(resp.data.profile));
        toast.success(resp.message);
        if (resp.data.profile.userType == "nurse") {
          navigate("/nurse/create-profile");
          dispatch(setNurseMenuSelected(0));
        } else {
          navigate("/hospital/create-profile");
          dispatch(setHospitalMenuSelected(0));
        }
      })
      .catch((Err) => {
        toast.error(Err.message);
      });
  };
  return (
    <Layout>
      <Box>
        <Formik
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
            TncCheck: false,
          }}
          validate={(values) => {
            const passRegex = RegExp("^.{8,20}$");
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            // else if (
            //   userType === "hospital" &&
            //   values.email &&
            //   isTestEmail(values.email)
            // ) {
            //   errors.email = "Please enter a valid work email.";
            // }
            if (!values.password) {
              errors.password = "Required";
            }
            if (!passRegex.test(values.password)) {
              errors.password = "Must contain between 8 to 20 characters";
            }
            if (!passRegex.test(values.confirmPassword)) {
              errors.confirmPassword =
                "Must contain between 8 to 20 characters";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Required";
            }
            if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm password should match.";
            }
            if (
              values.email &&
              !errors.email &&
              values.password &&
              !errors.password &&
              values.confirmPassword &&
              !errors.confirmPassword &&
              !values.TncCheck
            ) {
              errors.TncCheck = "Please accept T&C and Privacy policy check.";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleSignup(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Box marginBottom={"20px"}>
                <Input
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  startAdornment={<img src={EmailIcon} alt="email" />}
                  inputProps={{
                    style: {
                      padding: "10px",
                    },
                  }}
                  sx={{
                    padding: "10px 5px 10px 20px",
                    background: "#eff6f8",
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    "&:after": {
                      borderBottom: "none",
                    },
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                      borderBottom: "none",
                    },
                    "&.Mui-focused": {
                      borderBottom: "none",
                    },
                  }}
                />
                {errors.email && touched.email && (
                  <Typography textAlign={"left"} variant="body2" color="red">
                    {errors.email}
                  </Typography>
                )}
              </Box>
              <Box marginBottom={"20px"}>
                <Input
                  type={passwordVisibility == "password" ? "text" : "password"}
                  name="password"
                  placeholder="Enter Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  startAdornment={<img src={PasswordIcon} alt="password" />}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{ paddingRight: "10px", cursor: "pointer" }}
                    >
                      {passwordVisibility == "password" ? (
                        <img
                          alt=""
                          src={EyeIcon}
                          onClick={() => setPasswordVisibility("")}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={EyeSlashIcon}
                          onClick={() => setPasswordVisibility("password")}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      padding: "10px",
                    },
                  }}
                  sx={{
                    padding: "10px 5px 10px 20px",
                    background: "#eff6f8",
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    "&:after": {
                      borderBottom: "none",
                    },
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                      borderBottom: "none",
                    },
                    "&.Mui-focused": {
                      borderBottom: "none",
                    },
                  }}
                />
                {errors.password && touched.password && (
                  <Typography textAlign={"left"} variant="body2" color="red">
                    {errors.password}
                  </Typography>
                )}
              </Box>
              <Box>
                <Input
                  type={
                    passwordVisibility == "confirmPassword"
                      ? "text"
                      : "password"
                  }
                  name="confirmPassword"
                  placeholder="Re-enter Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  startAdornment={<img src={PasswordIcon} alt="password" />}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{ paddingRight: "10px", cursor: "pointer" }}
                    >
                      {passwordVisibility == "confirmPassword" ? (
                        <img
                          alt=""
                          src={EyeIcon}
                          onClick={() => setPasswordVisibility("")}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={EyeSlashIcon}
                          onClick={() =>
                            setPasswordVisibility("confirmPassword")
                          }
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  }
                  inputProps={{
                    style: {
                      padding: "10px",
                    },
                  }}
                  sx={{
                    padding: "10px 5px 10px 20px",
                    background: "#eff6f8",
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                    "&:after": {
                      borderBottom: "none",
                    },
                    "&:before": {
                      borderBottom: "none",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                      borderBottom: "none",
                    },
                    "&.Mui-focused": {
                      borderBottom: "none",
                    },
                  }}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <Typography textAlign={"left"} variant="body2" color="red">
                    {errors.confirmPassword}
                  </Typography>
                )}
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                  }}
                  name="TncCheck"
                  onChange={handleChange}
                  checked={values.TncCheck}
                  icon={<img src={tickCircle} alt="checked" />}
                  checkedIcon={<img src={tickCheckedCircle} alt="checked" />}
                />
                <Typography
                  sx={{ textAlign: "left" }}
                  fontSize={"10.5px"}
                  color="#8592a1"
                >
                  By tapping here you agree to our{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#38879f",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/terms-and-conditions")}
                  >
                    Terms and Conditions
                  </span>{" "}
                  &{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#38879f",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/privacy-policy")}
                  >
                    Privacy Policy.
                  </span>
                </Typography>
              </Box>
              {errors.TncCheck && (
                <Typography textAlign={"left"} variant="body2" color="red">
                  {errors.TncCheck}
                </Typography>
              )}
              <Button
                type="submit"
                disabled={
                  isSubmitting ||
                  Object.entries(errors).length > 0 ||
                  Object.values(values).every((i) => !i)
                }
                sx={{
                  width: "100%",
                  height: "55px",
                  marginTop: "15px",
                  background: "#38879f",
                  color: "#fff",
                  fontWeight: 600,
                  borderRadius: "10px",
                  "&.Mui-disabled": {
                    background: "#adcdd7",
                    color: "#fff",
                  },
                  "&:hover": {
                    backgroundColor: "#38879f",
                  },
                }}
              >
                Sign Up
              </Button>
              <Typography fontSize={"14px"} color="#8592a1" marginTop={"25px"}>
                Already have an account?{" "}
                <span
                  style={{
                    fontWeight: 600,
                    color: "#38879f",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (userType === "hospital") {
                      navigate("/hospital/signin");
                    } else {
                      navigate("/nurse/signin");
                    }
                  }}
                >
                  Sign In
                </span>
              </Typography>
            </form>
          )}
        </Formik>
      </Box>
    </Layout>
  );
}
